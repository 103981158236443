<template>
  <div
    v-if="props.open"
    :class="[
      'fixed left-0 top-0 z-50 flex h-screen w-screen justify-center overflow-auto bg-black/50 max-md:pt-20',
      props.className,
    ]"
    @click.stop="onClose"
  >
    <div
      class="m-auto w-full max-w-full animate-fade-up overflow-hidden rounded-t-2xl border border-solid border-[#50494580] text-white max-md:mb-0 md:w-auto md:rounded-b-2xl"
      style="overflow: hidden"
      :class="props.bodyClass"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
type BgWrapperProps = {
  className?: string
  bodyClass?: string
  open: boolean
}
const props = defineProps<BgWrapperProps>()

const emits = defineEmits<{
  close: []
}>()
const onClose = (e: MouseEvent) => {
  if (e.target === e.currentTarget) {
    emits('close')
  }
}
</script>
